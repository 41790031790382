<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Reçu Paiement N° {{ payment.payment_no }}
          </h2>
        </div>
        <div>
          <el-button
            icon="el-icon-printer"
            :loading="dowloandLoading"
            @click="exportRecuPDF"
            >Imprimer</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            v-if="payment.status === 'PAID'"
            @click="handleAnnuler"
            >Annuler</el-button
          >
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto bg-white p-5 shadow">
        <div class="flex justify-between items-start">
          <div class="flex flex-col w-1/2">
            <h1
              class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5"
            >
              {{ payment.contact_name }}
            </h1>
            <div class="flex flex-col bg-gray-100 w-2/3 p-3">
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">N°</span>
                <span>{{ payment.contact_no }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">Téléphone</span>
                <span>{{ payment.contact_phone }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">Email</span>
                <span>{{ payment.contact_email }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-500">Adresse</span>
                <span>{{ payment.contact_street }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-500">Correspondant</span>
                <span
                  >{{ payment.contact_lastName }} -
                  {{ payment.contact_fistname }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-end items-end w-1/2">
            <div
              class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
            >
              Montant : {{ payment.total | moneyFilter }} {{user.currency_code}}
            </div>
            <div class="flex flex-col justify-between w-1/2 mt-4">
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Date :</span>
                <span>{{ payment.payment_date }}</span>
              </div>
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Mode de paiement :</span>
                <span>{{ payment.payment_mode }}</span>
              </div>
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Réference </span>
                <span>{{ payment.reference }}</span>
              </div>
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Statut </span>
                <Status :name="payment.status" />
              </div>
            </div>

            <div class="flex flex-col w-1/2">
              <el-divider content-position="left"
                >Comptes de reception
              </el-divider>
              <div class="flex justify-between my-1 font-medium">
                <div class="flex flex-col justify-center">
                  <span> {{ payment.payment_account }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="customer-white mt-3">
          <el-table :data="payment.items" style="width: 100%">
            <el-table-column prop="" label="#" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column
              prop="invoiceNo"
              label="N° FACTURE"
              min-width="150"
            >
              <template slot-scope="{ row }">
                {{ row.document_no }}
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="TOTAL TTC"
              width="220"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.total | moneyFilter }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="amount"
              label="RESTE À PAYER"
              width="220"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.remaining_to_pay | moneyFilter }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="amount"
              label="MONTANT"
              width="220"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.amount | moneyFilter }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <CancelPayment
      :number="payment.payment_no"
      :dialogVisible="dialogVisible"
      @handleNo="handleCancel"
      @handleYes="handleYes"
    />
  </div>
</template>

<script>
import { getPaymentInvoice } from "@/api/sales2";
import { exportRecuPDF } from "@/api/export";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import CancelPayment from "@/components/CancelPayment";
import Status from "@/components/Status";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";
export default {
  name: "CreateInvoice",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { CancelPayment, Status, Loading },
  data() {
    return {
      labelPosition: "top",
      loadingFull: false,
      dialogVisible: false,

      dowloandLoading: false,
      payment: {
        note: null,
        total: 0,
        payment_no: "",
        payment_account: "",
        payment_date: "",
        payment_id: "",
        payment_mode: "",
        reference: "",
        status: "PAID",
        items: [],
      },
    };
  },
  computed:{
      ...mapGetters(["user"]),
  },
  mounted() {
    const id = this.$route.params && this.$route.params.id;
    this.fetchData(id);
  },
  methods: {
    async fetchData(numero) {
      this.loadingFull = true;
      await getPaymentInvoice(numero)
        .then((res) => {
          setTimeout(() => {
            this.payment = Object.assign({}, res.data);
            this.loadingFull = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAnnuler() {
      this.dialogVisible = true;
    },
    handleCancel(e) {
      this.dialogVisible = e;
    },
    handleYes(e) {
      this.dialogVisible = e;
      this.payment.status = "CANCELED";
    },
    exportRecuPDF() {
      this.dowloandLoading = true;
      exportRecuPDF(this.payment.payment_no)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "recu-" + this.payment.payment_no + ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.dowloandLoading = false;
          }, 1000);
        })
        .catch((err) => {
          this.dowloandLoading = false;
          console.log(err);
          console.log("hello");
        });
    },
  },
};
</script>

<style></style>
